import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap styles
import AdminPanel from "./Admin";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import LocalStorageService from "./LocalStorage";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [otpError, setOtpError] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [targetOtp, setTargetOtp] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [error, setError] = useState("");
  const [isLoggedIn, setLoggedIn] = useState(LocalStorageService.isLoggedIn());
  const { t } = useTranslation();

  const baseUrl = "http://89.116.21.245:5000";
  // const baseUrl = "http://localhost:5000";

  const handleLogin = async () => {
    setError("");
    // Simple validation for demonstration purposes
    if (username === "admin" && password === "password189") {
      // Successful login logic
      await sendAndGetOTP();
    } else {
      //   setError();
      setError(t("login.error"));
    }
  };

  const sendAndGetOTP = async () => {
    try {
      setLoading(true);
      let otp = await axios.get(baseUrl + "/api/login/otp");
      console.log(otp);
      setTargetOtp(otp.data.otp);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      toast.error("Failed to send OTP");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = () => {
    // Add your OTP validation logic here
    // If OTP is valid, setLoggedIn and close the modal
    // Otherwise, show an error message
    if (parseInt(otp) === parseInt(targetOtp)) {
      LocalStorageService.setLoggedIn(true);
      setLoggedIn(true);
      setShowModal(false);
      toast.success(t("login.success"));
      console.log("Login successful!");
    } else {
      setOtpError(t("login.otpError"));
    }
  };

  return (
    <div className="">
      {isLoggedIn ? (
        <AdminPanel />
      ) : (
        <div className=" container mt-5 card">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="card-title">{t("login.title")}</h2>
              <LanguageSwitcher />
            </div>
            <form>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  {t("login.username")} :
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  {t("login.password")} :
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleLogin}
              >
                {" "}
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                )}
                {t("login.login")}
              </button>
              {error && <p className="text-danger mt-2">{error}</p>}
            </form>
          </div>{" "}
          {/* OTP Modal */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{t("login.enterOtp")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <label htmlFor="otp" className="form-label">
                  {t("login.otp")} :
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              {otpError && <p className="text-danger">{otpError}</p>}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                {t("common.cancel")}
              </Button>
              <Button variant="primary" onClick={handleOtpSubmit}>
                {t("common.submit")}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AdminLogin;
