class LocalStorageService {
  static setLoggedIn(isLoggedIn) {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }

  static isLoggedIn() {
    return localStorage.getItem("isLoggedIn") === "true";
  }

  static logout() {
    localStorage.removeItem("isLoggedIn");
  }
}

export default LocalStorageService;
