import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import {
  generateRandomString,
  getKoreanDateString,
  getSecondsPassedKorean,
  secondsToHMS,
  secondsToTimeString,
  timeStringToSeconds,
} from "./helper";
import { toast } from "react-toastify";
import TimePicker from "react-time-picker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Navbar,
  Row,
  Table,
} from "react-bootstrap";
import LocalStorageService from "./LocalStorage";

const AdminPanel = () => {
  const { t } = useTranslation();

  
  const baseUrl = "http://89.116.21.245:5000";
  // const baseUrl = "http://localhost:5000";
  const [probabilityMap, setProbabilityMap] = useState({});
  const [newSeconds, setNewSeconds] = useState(200);
  const [newProbability, setNewProbability] = useState(0.5);
  // const [, setSelectedSeconds] = useState(null);
  const [glowingSeconds, setGlowingSeconds] = useState(0);

  const [tradeValue, setTradeValue] = useState("");
  const [orderBookVolume, setOrderBookVolume] = useState("");
  const [stopBot, setStopBot] = useState(true);
  const [minimumUsdt, setMinimumUsdt] = useState(0);
  const [minimumToken, setMinimumToken] = useState(0);
  const [maxTokenPrice, setMaxTokenPrice] = useState(10);

  const [currentSparkTime, setCurrentSparkTime] = useState(0);

  const [volume24hr, setVolume24Hr] = useState(0);

  const [sparks, setSparks] = useState({
    0: {
      time: 0,
      amount: 0,
    },
  });
  const [sparkIndex, setSparkIndex] = useState(0);
  const [currentOrderBook, setCurrentOrderBook] = useState({
    totalVolume: 0,
    usdtAmount: 0,
    tokenAmount: 0,
  });
  const [accountAssets, setAccountAssets] = useState({
    usdt: 0,
    token: 0,
  });

  const [speedRun, setSpeedRun] = useState({
    time: 0,
    isRunning: false,
    accomplished: false,
    target: 0,
    timePeriodS: 0,
    amount: 0,
  });
  const [currentPrice, setCurrentPrice] = useState(0);

  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    fetchSettings();
    fetchUserInfo();
    fetchUpdateOrderBookDetails();
    fetchCurrentPrice();
    // setInterval(() => {
    //   fetchUpdateOrderBookDetails();
    //   fetchCurrentPrice();
    // }, 5000);
    console.log(t);
  }, []);

  useEffect(() => {
    setCurrentSparkTime(sparks[sparkIndex].time);
  }, [sparkIndex, sparks]);
  useEffect(() => {
    let curSeconds = getSecondsPassedKorean();
    let reqSecond = 0;
    Object.entries(probabilityMap).map(([seconds, item]) => {
      if (seconds <= curSeconds) {
        reqSecond = seconds;
      }
    });
    setGlowingSeconds(reqSecond);
  }, [probabilityMap]);
  const fetchProbabilityList = async () => {
    axios
      .post(baseUrl + "/api/probability-settings", {
        date: getKoreanDateString(currentDate),
      })
      .then((response) => {
        setProbabilityMap(response.data.probabilityMap);
        console.log(response.data.probabilityMap);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddProbability = () => {
    // if (!newSeconds) return;
    if (newSeconds == null) {
      toast.info(t("messages.please_select"));
      return;
    }
    console.log(newSeconds);
    let totalSeconds = newSeconds;
    axios
      .put(`${baseUrl}/api/probability-settings/${totalSeconds}`, {
        probability: parseFloat(newProbability),
        date: getKoreanDateString(currentDate),
        maxBuyPriceGap: 200,
      })
      .then((response) => {
        setProbabilityMap(response.data.probabilityMap);
        // setNewSeconds(0);
        setNewProbability(0.5);
      })
      .catch((error) => {
        toast.info(t("messages.somethingWentWrong"));
      });
  };

  const handleRemoveProbability = (seconds) => {
    if (seconds === 0) {
      toast.info(t("messages.removeSuccess"));
    }
    axios
      .post(`${baseUrl}/api/probability-settings/${seconds}`, {
        date: getKoreanDateString(currentDate),
      })
      .then((response) => {
        toast.info(t("messages.removeSuccess"));
        setProbabilityMap(response.data.probabilityMap);
      })
      .catch((error) => {
        console.log(error);
        toast.info(error.message.toString());
      });
  };

  const handleUpdateProbability = (seconds, probability) => {
    axios
      .put(`${baseUrl}/api/probability-settings/${seconds}`, {
        probability: parseFloat(probability),
        date: getKoreanDateString(currentDate),
        tradeValue: probabilityMap[seconds].tradeValue,
        orderBookVolume: probabilityMap[seconds].orderBookVolume,
        maxBuyPriceGap: probabilityMap[seconds].maxBuyPriceGap,
      })
      .then((response) => {
        toast.info(t("messages.updated"));
      })
      .catch((error) => {
        toast.info(t("messages.somethingWentWrong"));
      });
  };

  const updateSparksState = async () => {
    // setTimeout(() => {
    //   setSparks(_map);
    // }, 1000);
    setSparkIndex(sparkIndex);
    setSparks(sparks);
  };
  const updateSettings = async () => {
    try {
      const response = await axios.put(baseUrl + "/api/settings-update", {
        tradeValue,
        orderBookVolume,
        stopBot,
        minimumUsdt,
        minimumToken,
        sparkTime: sparks[sparkIndex].time,
        sparkAmount: sparks[sparkIndex].amount,
        maxTokenPrice,
        index: sparkIndex,
      });
      toast.info(t("messages.updated"));
      updateSparksState();
    } catch (error) {
      toast.info(t("messages.somethingWentWrong"));
      console.error(error);
    }
  };
  const startSpeedRun = async () => {
    try {
      let response = await axios.post(baseUrl + "/api/settings-speedrun", {
        target: speedRun.target,
        seconds: speedRun.timePeriodS,
        amount: speedRun.amount,
      });
      toast.info(t("messages.speed_run_udpated"));
      setSpeedRun(response.data.speedRun);
    } catch (error) {
      console.log(error);
      toast.info(t("messages.somethingWentWrong"));
    }
  };
  const fetchSettings = async () => {
    try {
      let response = await axios.get(baseUrl + "/api/settings");
      setOrderBookVolume(response.data.orderBookVolume);
      setTradeValue(response.data.tradeValue);
      setStopBot(response.data.stopBot);
      setMinimumToken(response.data.minimumToken);
      setMinimumUsdt(response.data.minimumUsdt);
      setMaxTokenPrice(response.data.maxTokenPrice);
      setSpeedRun(response.data.speedRun);

      setSparks(response.data.spark);
      console.log(response.data.spark);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUpdateOrderBookDetails = async () => {
    try {
      let response = await axios.get(baseUrl + "/api/order_book_details");
      console.log(response.data);
      setCurrentOrderBook(response.data);
      response = await axios.get(baseUrl + "/api/volume_24hr");
      setVolume24Hr(response.data.volume);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchUserInfo = async () => {
    try {
      let response = await axios.get(baseUrl + "/api/user_balance");
      setAccountAssets({
        token: response.data.stoone,
        usdt: response.data.usdt,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrentPrice = async () => {
    try {
      let response = await axios.get(baseUrl + "/api/current_price");
      setCurrentPrice(response.data.price);
    } catch (error) {
      console.log(error);
    }
  };

  const convertSecondsToDate = (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date;
  };
  const updateMap = (map) => {
    setProbabilityMap(map);
  };

  useEffect(() => {
    fetchProbabilityList();
    console.log("fd");
  }, [currentDate]);
  return (
    <div>
      <Navbar className="bg-body-tertiary" expand="xxl" fixed="top">
        <Container>
          <Navbar.Brand href="">{t("title")}</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <LanguageSwitcher />
            <div className="d-flex align-items-center">
              {" "}
              {/* Added a container div */}
              <div className="ms-2">
                {" "}
                {/* Added margin to create space */}
                <Button
                  onClick={() => {
                    LocalStorageService.logout();
                    window.location.reload();
                  }}
                >
                  {t("settings.logout")}
                </Button>
              </div>
              {/* Other components or content */}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="mb-5 container" style={{ marginTop: "70px" }}>
        <div className="mt-5">
          <h3>{t("settings.statistics")}</h3>
          <Container className="mt-4 mb-4">
            <Row xs={1} md={3} lg={4} className="g-4">
              <Col>
                <Card>
                  <Card.Body>
                    <Form.Label>{t("statistics.volme24hr")}</Form.Label>
                    <Form.Control type="number" disabled value={volume24hr} />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Form.Label>{t("statistics.tokenprice")}</Form.Label>
                    <Form.Control type="number" disabled value={currentPrice} />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Form.Label>{t("statistics.orderbookvalue")}</Form.Label>
                    <Form.Control
                      type="number"
                      disabled
                      value={currentOrderBook.totalVolume}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Form.Label>{t("statistics.obusdt")}</Form.Label>
                    <Form.Control
                      type="number"
                      disabled
                      value={currentOrderBook.usdtAmount}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Form.Label>{t("statistics.obtoken")}</Form.Label>
                    <Form.Control
                      type="number"
                      disabled
                      value={currentOrderBook.tokenAmount}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Form.Label>{t("statistics.balanceusdt")}</Form.Label>
                    <Form.Control
                      type="number"
                      disabled
                      value={accountAssets.usdt}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Form.Label>{t("statistics.balancetoken")}</Form.Label>
                    <Form.Control
                      type="number"
                      disabled
                      value={accountAssets.token}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container className="mt-4">
            <Row xs={1} md={2} lg={2} className="g-4">
              <Col>
                <h3>{t("speedrun.speed_run")}</h3>
                <Card>
                  <Card.Body>
                    <Form.Label>{t("speedrun.target_price")}</Form.Label>
                    <Form.Control
                      type="number"
                      value={speedRun.target}
                      onChange={(e) => {
                        setSpeedRun({
                          ...speedRun,
                          target: e.target.value,
                        });
                      }}
                    />
                    <Form.Label>{t("settings.seconds")}</Form.Label>
                    <Form.Control
                      type="number"
                      value={speedRun.timePeriodS}
                      onChange={(e) => {
                        setSpeedRun({
                          ...speedRun,
                          timePeriodS: e.target.value,
                        });
                      }}
                    />
                    <Form.Label>{t("settings.amount")}</Form.Label>
                    <Form.Control
                      type="number"
                      value={speedRun.amount}
                      onChange={(e) => {
                        setSpeedRun({
                          ...speedRun,
                          amount: e.target.value,
                        });
                      }}
                    />
                    <br />
                    <div className="d-flex justify-content-between">
                      <Button variant="info">
                        {speedRun.isRunning ? "Active" : "Not Active"}
                      </Button>
                      <Button
                        disabled={speedRun.isRunning}
                        onClick={startSpeedRun}
                      >
                        {t("settings.start")}
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <h3>{t("settings.spark_settings")}</h3>
                {sparks && (
                  <Card key={generateRandomString()}>
                    <Card.Body>
                      <Form.Label>{t("settings.spark_no") + " : "} </Form.Label>
                      <Form.Select
                        aria-label="Select spark"
                        onChange={(e) => {
                          setSparkIndex(parseInt(e.target.value) - 1);
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Form.Select>
                      <Form.Label>{t("settings.spark")}</Form.Label>
                      <br />
                      <TimePicker
                        showSecond={true}
                        value={secondsToTimeString(currentSparkTime)}
                        onChange={(time) => {
                          console.log(time);
                          sparks[sparkIndex].time = timeStringToSeconds(time);
                          setSparks(sparks);
                          setCurrentSparkTime(timeStringToSeconds(time));
                        }}
                        format={"h:mm a"}
                        use12Hours
                        inputReadOnly
                      />
                      <br />
                      <Form.Label className="mt-2">
                        {t("settings.amount")}
                      </Form.Label>
                      <Form.Control
                        key={generateRandomString(10)}
                        step=".01"
                        type="number"
                        defaultValue={sparks[sparkIndex].amount}
                        onChange={(e) => {
                          sparks[sparkIndex].amount = parseFloat(
                            e.target.value
                          );
                          updateSparksState();
                        }}
                      />
                      <br />

                      <div className="d-flex justify-content-between">
                        <Button onClick={updateSettings}>
                          {t("settings.update")}
                        </Button>
                        <Button
                          variant="info"
                          onClick={() => {
                            sparks[sparkIndex].amount = parseFloat(0);
                            updateSparksState();
                            updateSettings();
                          }}
                        >
                          {t("settings.disable")}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
          </Container>
          <h3 className="mt-4">{t("settings.settings")}</h3>

          <form>
            <Container className="mt-4">
              <Row xs={1} md={2} lg={4} className="g-4">
                <Col>
                  <Card>
                    <Card.Body>
                      <Form.Label>{t("settings.tradeValue")}</Form.Label>
                      <Form.Control
                        type="number"
                        value={tradeValue}
                        onChange={(e) => setTradeValue(e.target.value)}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body>
                      <Form.Label>{t("settings.orderBookVolume")}</Form.Label>
                      <Form.Control
                        type="number"
                        value={orderBookVolume}
                        onChange={(e) => setOrderBookVolume(e.target.value)}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body>
                      <Form.Label>{t("settings.stopBot")}</Form.Label>

                      <Form.Select
                        value={stopBot}
                        onChange={(e) => setStopBot(e.target.value)}
                      >
                        <option value="false">{t("settings.start")}</option>
                        <option value="true">{t("settings.stop")}</option>
                      </Form.Select>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body>
                      <Form.Label>{t("settings.minUsdt")}</Form.Label>
                      <Form.Control
                        type="number"
                        value={minimumUsdt}
                        onChange={(e) => setMinimumUsdt(e.target.value)}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body>
                      <Form.Label>{t("settings.minToken")}</Form.Label>
                      <Form.Control
                        type="number"
                        value={minimumToken}
                        onChange={(e) => setMinimumToken(e.target.value)}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body>
                      <Form.Label>{t("settings.maxpricetoken")}</Form.Label>
                      <Form.Control
                        type="number"
                        value={maxTokenPrice}
                        onChange={(e) => setMaxTokenPrice(e.target.value)}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
            <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={updateSettings}
            >
              {t("settings.updateSettings")}
            </button>
          </form>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
          <h3>{t("settings.probabilitySettings")}</h3>{" "}
          <div className="">
            <span>Select Date : </span>
            <DatePicker
              selected={currentDate}
              onSelect={(_date) => {
                setCurrentDate(_date);
              }} //when day is clicked
            />
          </div>
        </div>
        {/* <h4>{t("settings.addProbability")}</h4> */}

        <div
          className="mt-3"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div className="form-group d-flex" style={{ gap: "10px" }}>
            <label>{t("settings.time")}</label>

            <TimePicker
              showSecond={true}
              value={secondsToTimeString(newSeconds)}
              onChange={(time) => {
                setNewSeconds(timeStringToSeconds(time));
              }}
              format={"h:mm a"}
              use12Hours
              inputReadOnly
            />
          </div>
          <div
            className="form-group d-flex"
            style={{
              gap: "10px",
            }}
          >
            <label>{t("settings.probability")}</label>
            <div className="d-flex align-items-center">
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={newProbability}
                onChange={(e) => setNewProbability(parseFloat(e.target.value))}
                className="form-control-range mr-2 "
              />
              {newProbability.toFixed(2)}
            </div>
          </div>
          <button className="btn btn-primary" onClick={handleAddProbability}>
            {t("settings.add")}
          </button>
        </div>
        <table className="table mt-5">
          <thead>
            <tr>
              <th>{t("settings.date")}</th>
              <th>{t("settings.time")}</th>
              <th>{t("settings.slider")}</th>
              <th>{t("settings.probability")}</th>
              <th>{t("settings.tradeValue")}</th>
              <th>{t("settings.orderBookVolume")}</th>
              <th>{t("settings.maxsellgap")}</th>
              <th>{t("settings.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {probabilityMap &&
              Array.from(Object.entries(probabilityMap)).map(
                ([seconds, item]) => (
                  <tr
                    key={generateRandomString(10)}
                    style={{
                      verticalAlign: "middle",
                      backgroundColor:
                        glowingSeconds == seconds ? "#ffcc00" : "#ffffff", // Change based on condition
                      boxShadow:
                        glowingSeconds == seconds
                          ? "0 0 3px 3px #ffcc00"
                          : "none", // Change based on condition
                      zIndex: glowingSeconds == seconds ? 10 : 0,
                      position: "relative",
                    }}
                  >
                    <td>{getKoreanDateString(currentDate)}</td>
                    <td>{secondsToHMS(seconds)}</td>
                    <td>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        defaultValue={item.probability}
                        onChange={(e) => {
                          probabilityMap[seconds].probability = e.target.value;
                          updateMap(probabilityMap);
                          document.getElementById(
                            "range" + seconds.toString()
                          ).innerText = e.target.value;
                        }}
                        className="form-control-range mr-2 w-100"
                      />
                    </td>
                    <td>
                      <span id={"range" + seconds.toString()}>
                        {item.probability}
                      </span>
                    </td>
                    <td>
                      <Form.Control
                        style={{
                          width: "100px",
                          textAlign: "center",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                        type="number"
                        step=".01"
                        defaultValue={item.tradeValue}
                        onChange={(e) => {
                          probabilityMap[seconds].tradeValue = e.target.value;
                          updateMap(probabilityMap);
                        }}
                      />
                    </td>
                    <td>
                      {" "}
                      <Form.Control
                        style={{
                          width: "100px",
                          textAlign: "center",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                        type="number"
                        step=".01"
                        defaultValue={item.orderBookVolume}
                        onChange={(e) => {
                          probabilityMap[seconds].orderBookVolume =
                            e.target.value;
                          updateMap(probabilityMap);
                        }}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Form.Control
                        style={{
                          width: "100px",
                          textAlign: "center",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                        type="number"
                        step=".01"
                        defaultValue={item.maxBuyPriceGap}
                        onChange={(e) => {
                          probabilityMap[seconds].maxBuyPriceGap =
                            e.target.value;
                          updateMap(probabilityMap);
                        }}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-danger btn-sm mr-2"
                        onClick={() => handleRemoveProbability(seconds)}
                      >
                        {t("settings.remove")}
                      </button>
                      <button
                        className="btn btn-info btn-sm ms-3"
                        onClick={() => {
                          handleUpdateProbability(
                            seconds,
                            document.getElementById(
                              "range" + seconds.toString()
                            ).innerText
                          );
                        }}
                      >
                        {t("settings.update")}
                      </button>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPanel;
