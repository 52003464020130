import logo from "./logo.svg";
import "./App.css";
import AdminPanel from "./Admin";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
// import "rc-time-picker/assets/index.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import AdminLogin from "./Login";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <>
      <AdminLogin />
      <ToastContainer />
    </>
  );
}

export default App;
