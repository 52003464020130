// LanguageSwitcher.js

import React from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <NavDropdown title="Language" id="language-dropdown">
      <NavDropdown.Item onClick={() => changeLanguage("en")}>
        English
      </NavDropdown.Item>
      <NavDropdown.Item onClick={() => changeLanguage("ko")}>
        Korean
      </NavDropdown.Item>
      {/* Add more languages as needed */}
    </NavDropdown>
  );
};

export default LanguageSwitcher;
