export const secondsToHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    remainingSeconds
  )}`;
  return formattedTime;
};

function padZero(value) {
  return value.toString().padStart(2, "0");
}

export const getKoreanDateString = (date) => {
  const currentDate = date;
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "Asia/Seoul",
  };
  let koreanDate = new Intl.DateTimeFormat("pl", options).format(currentDate);
  koreanDate = koreanDate.replaceAll(".", "-");
  return koreanDate;
};
export const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};

export const timeStringToSeconds = (timeString) => {
  try {
    // Parse the time string into hours and minutes
    const timeParts = timeString.split(":");
    const hours = parseInt(timeParts[0], 10) || 0;
    const minutes = parseInt(timeParts[1], 10) || 0;

    // Calculate the total seconds
    const totalSeconds = hours * 3600 + minutes * 60;

    return totalSeconds;
  } catch (error) {
    console.log(error);
    return 0;
  }
};
export const secondsToTimeString = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  // Format the time string
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return formattedTime;
};
export const getSecondsPassedKorean = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  let secondsPassedCurTZ = Math.floor((new Date() - currentDate) / 1000);

  const currentDateTime = new Date();
  const timezoneOffsetInMinutes = currentDateTime.getTimezoneOffset();
  const timezoneOffsetInSeconds = timezoneOffsetInMinutes * 60;

  let secondsPassed =
    (secondsPassedCurTZ + timezoneOffsetInSeconds + 9 * 60 * 60 + 86400) %
    86400;

  return secondsPassed;
};
